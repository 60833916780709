import { Link } from 'gatsby'
import { Seo, ContactForm } from '~/components'
import Arrow from '~/images/common_icon_arrow_r.svg'

const ContactOrder = () => (
  <>
    <Seo title="ご注文前のお問い合わせ" pageUrl="contact-order" />
    <div className="pageComponent">
      <article
        className="contact"
        itemScope
        itemType="http://schema.org/Article"
      >
        <header className="titleComponent contentComponent">
          <h1 className="heading pageTitle">CONTACT</h1>
          <p>ご注文前のお問い合わせ</p>
        </header>
        <nav className="contentComponent">
          <ul className="breadcrumbComponent mincho">
            <li>
              <Link to="/" itemProp="url">
                TOP
              </Link>{' '}
              <span>
                <Arrow />
              </span>
            </li>
            <li>CONTACT</li>
          </ul>
        </nav>
        <div className="contentSubComponent">
          <ContactForm formId="335c990b-0f37-4385-ac54-fbbf71ca77dc" />
        </div>
      </article>
    </div>
  </>
)

export default ContactOrder
